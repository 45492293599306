import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import { Link } from 'gatsby';
import Zoom from 'react-img-zoom';

import ReactMarkdownMain, * as ReactMarkdownAlt from 'react-markdown/with-html';
import { View } from '../View/View';
import { Visible } from '../Visible/Visible';

const ReactMarkdown = ReactMarkdownMain || ReactMarkdownAlt;

const StyledHeroImage = styled.div`
  min-width: 100%;
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  z-index: -10;
  font-size: 18px;
  line-height: 20px;

  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    position: relative;
    width: 321px;
    height: 192px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    height: 65vh;
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    /* font-style: normal; */
    font-weight: bold;
    text-align: center;
    color: ${design.colors.white};
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      font-size: 18px;
      line-height: 20px;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      font-weight: bold;
      font-size: 47px;
      line-height: 52px;
      text-align: center;
    }
  }
`;
const Container = styled.div`
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    width: 100%;
    height: 44px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    width: 500px;
    height: 95px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
`;

const HeroImage = props => {
  return (
    <>
      {props.inCarousel && props.disableLink ? (
        <>
          <Visible desktop>
            <Link to={props.url}>
              <View center>
                <Zoom img={props.src} zoomScale={3} width={1000} height={600} />
              </View>
              {/* <Container>
              <h1>
                <ReactMarkdown source={props.content} escapeHtml={false} />
              </h1>
            </Container> */}
              {/* </ReactImageZoom> */}
            </Link>
          </Visible>
          <Visible mobile tablet>
            <StyledHeroImage src={props.src}>
              <Container>
                <h1>
                  <ReactMarkdown source={props.content} escapeHtml={false} />
                </h1>
              </Container>
            </StyledHeroImage>
          </Visible>
        </>
      ) : props.withoutZoom ? (
        <>
          <Visible desktop>
            <Link to={props.url}>
              <View center>
                <StyledHeroImage src={props.src} />
              </View>
            </Link>
          </Visible>
          <Visible mobile tablet>
            <StyledHeroImage src={props.src}>
              <Container>
                <h1>
                  <ReactMarkdown source={props.content} escapeHtml={false} />
                </h1>
              </Container>
            </StyledHeroImage>
          </Visible>
        </>
      ) : (
        <StyledHeroImage src={props.src}>
          <Container>
            <h1>
              <ReactMarkdown source={props.content} escapeHtml={false} />
            </h1>
          </Container>
        </StyledHeroImage>
      )}
    </>
  );
};

export default HeroImage;
