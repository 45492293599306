import React from 'react';
import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import design from '../config/design';
import YouTube from 'react-youtube';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../components/Title/Title';
import { Visible } from '../components/Visible/Visible';
import { View } from '../components/View/View';
import Button from '../components/Button/Button';
import Text from '../components/Text/Text';
import HeroImage from '../components/HeroImage/HeroImage';
import { BigView } from '../components/BigView/BigView';

function About() {
  const _onReady = event => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const { about } = useStaticQuery(
    graphql`
      query {
        about: allDatoCmsAboutPage {
          edges {
            node {
              buttonUrl
              textButton
              youtubeVideoId
              aboutContent {
                sectionTitle
                sectionText
                sectionTextImage
                sectionImage {
                  url
                }
              }
            }
          }
        }
      }
    `
  );

  const content = about.edges[0].node;

  return (
    <>
      <LayoutWrapper>
        <BigView>
          <View
            desktopLeft={3}
            desktopRight={3}
            mobileLeft={1}
            mobileRight={1}
            tabletLeft={3}
            tabletRight={3}
            bottom={2}
          >
            <View top={2} />
            {content.aboutContent.map(item => (
              <>
                <HeroImage
                  src={item.sectionImage.url}
                  content={item.sectionTextImage}
                />
                <View top={3} />
                <Title
                  color={design.colors.blue150}
                  content={item.sectionTitle}
                />
                <View top={0.5} />
                <Text color={design.colors.dark} content={item.sectionText} />
                <View top={3.5} />
              </>
            ))}

            <Visible mobile tablet>
              <YouTube
                videoId={content.youtubeVideoId}
                opts={{ height: '193', width: '100%' }}
                onReady={_onReady}
              />
            </Visible>
            <Visible desktop>
              <YouTube
                videoId={content.youtubeVideoId}
                opts={{ height: '720', width: '100%' }}
                onReady={_onReady}
              />
            </Visible>
            <View top={2} center>
              <Button
                href={content.buttonUrl}
                content={content.textButton}
                bgColor="primaryOrange"
                width="full"
              />
            </View>
          </View>
        </BigView>
      </LayoutWrapper>
    </>
  );
}

export default About;
